<template>
    <div>
        <div ref="editorElem"></div>

        <input type="hidden" :name="inputName" :value="proxyValue">
    </div>
</template>

<script>
import striptags from 'striptags';
import { exec, init } from 'pell';
import 'pell/dist/pell.css';

export default {
    props: {
        inputName: {
            type: String,
            default: '',
        },

        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            editor: null,
            proxyValue: this.value,
        };
    },

    mounted() {
        const { editorElem } = this.$refs;

        this.editor = init({
            element: editorElem,
            defaultParagraphSeparator: 'div',
            styleWithCSS: false,
            actions: [
                'bold',
                'underline',
                {
                    name: 'link',
                    result: () => {
                        const url = window.prompt('Enter the link URL');

                        if (url) {
                            exec('createLink', url);
                        }
                    },
                },
            ],
            onChange: (html) => {
                this.proxyValue = html;
            },
        });

        this.editor.content.innerHTML = this.proxyValue;
        this.editor.content.addEventListener('focusin', this.focus);
        this.editor.content.addEventListener('focusout', this.blur);
        this.editor.addEventListener('paste', this.onPaste);
    },

    beforeUnmount() {
        this.editor.content.removeEventListener('focusin', this.focus);
        this.editor.content.removeEventListener('focusout', this.blur);
        this.editor.removeEventListener('paste', this.onPaste);
    },

    methods: {
        onPaste(event) {
            event.stopPropagation();
            event.preventDefault();

            const clipboardData = event.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('text/html');

            if (pastedData === '') {
                pastedData = clipboardData.getData('text/plain');
            }

            const tagWhiteList = ['div', 'p', 'br', 'b', 'strong', 'em', 'i', 'a'];
            pastedData = striptags(pastedData, tagWhiteList);

            const wrapper = document.createElement('div');
            wrapper.innerHTML = pastedData;

            const allChildren = wrapper.getElementsByTagName('*');
            for (let index = 0; index < allChildren.length; index++) {
                const element = allChildren[index];
                element.removeAttribute('id');
                element.removeAttribute('class');
                element.removeAttribute('style');
            }

            pastedData = wrapper.innerHTML;

            exec('insertHTML', pastedData);
        },
    },
};

</script>
