import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core';

const httpLink = new HttpLink({
    uri: '/api',
    headers: {
        Authorization: 'Bearer eFk4h4zdvGYIn1NQtPlCjnAcMwjiea33',
    },
});

// Create the apollo client
const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

export default apolloClient;
