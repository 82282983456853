<script>
import debounce from 'lodash/debounce';
import qs from 'qs';

import { h } from 'vue';

export default {
    name: 'FilteredElements',

    props: {
        template: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            mounted: false,
            search: '',
            loading: false,
            params: {},
        };
    },

    watch: {
        search: debounce(function() {
            if (this.mounted) {
                this.onSelectChange({ search: this.search });
            }
        }, 500),
    },

    created() {
        // Populate any existing params
        this.params = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        this.search = this.params.search;

        // Prevent watched changed firing too early (on init)
        setTimeout(() => {
            this.mounted = true;
        }, 1000);
    },

    methods: {
        onSelectChange(values) {
            this.loading = true;

            // Convert to pipe-delimited for better SEO
            if (values.categories) {
                values.categories = values.categories.join('|');
            }

            this.params = {
                ...this.params,
                ...values,
            };

            history.replaceState(
                null,
                '',
                `?${qs.stringify(this.params, { encode: false })}`,
            );

            this.fetchHtml();
        },

        onTopicChange(values) {
            this.loading = true;

            // Convert to pipe-delimited for better SEO
            if (values.topics) {
                values.topics = values.topics.join('|');
            }

            this.params = {
                ...this.params,
                ...values,
            };

            history.replaceState(
                null,
                '',
                `?${qs.stringify(this.params, { encode: false })}`,
            );

            this.fetchHtml();
        },

        onRegionChange(values) {
            this.loading = true;

            // Convert to pipe-delimited for better SEO
            if (values.regions) {
                values.regions = values.regions.join('|');
            }

            this.params = {
                ...this.params,
                ...values,
            };

            history.replaceState(
                null,
                '',
                `?${qs.stringify(this.params, { encode: false })}`,
            );

            this.fetchHtml();
        },

        fetchHtml: debounce(async function() {
            // Fetch the HTML and replace via DOM. ugly, but whatevs
            const $entries = this.$refs.container.querySelector('#entries');

            if (this.template && $entries) {
                const response = await fetch(
                    `${this.template}?${new URLSearchParams(
                        this.params,
                    ).toString()}`,
                );

                console.log(response);

                const data = await response.text();

                $entries.innerHTML = data;

                this.loading = false;
            }
        }, 400),
    },

    render() {
        return h(
            'div',
            { ref: 'container' },
            this.$slots.default({
                loading: this.loading,
                search: this.search,
                params: this.params,
                onSelectChange: this.onSelectChange,
                onRegionChange: this.onRegionChange,
                onTopicChange: this.onTopicChange,
            }),
        );
    },
};
</script>
