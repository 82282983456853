<template>
    <nav v-if="pages > 1">
        <ul class="flex m-0 p-0 list-none justify-center">
            <li class="mx-1">
                <button
                    rel="prev"
                    class="inline-block py-2 px-4 no-underline text-grey-700 focus:outline-none"
                    :disabled="current <= 1"
                    @click.prevent="$emit('changed', current - 1)"
                >
                    <span>Previous</span>
                </button>
            </li>

            <li v-for="n in prevPages" :key="n" class="mx-1">
                <button
                    class="inline-block py-2 px-4 no-underline text-white bg-grey-500 rounded-lg hover:bg-primary-500 focus:outline-none"
                    :disabled="current === n"
                    @click.prevent="$emit('changed', n)"
                >
                    <span>{{ n }}</span>
                </button>
            </li>

            <li class="mx-1">
                <span class="inline-block py-2 px-4 no-underline text-white bg-tertiary-500 rounded-lg">
                    {{ current }}
                </span>
            </li>

            <li v-for="n in nextPages" :key="n" class="mx-1">
                <button
                    class="inline-block py-2 px-4 no-underline text-white bg-grey-500 rounded-lg hover:bg-primary-500 focus:outline-none"
                    :disabled="current === n"
                    @click.prevent="$emit('changed', n)"
                >
                    <span>{{ n }}</span>
                </button>
            </li>

            <li class="mx-1">
                <button
                    rel="next"
                    class="inline-block py-2 px-4 no-underline text-grey-700 focus:outline-none"
                    :disabled="current >= pages"
                    @click.prevent="$emit('changed', current + 1)"
                >
                    <span>Next</span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>

export default {
    name: 'Pagination',

    props: {
        items: {
            type: Number,
            required: true,
        },

        itemsPerPage: {
            type: Number,
            required: true,
        },

        current: {
            type: Number,
            required: true,
        },
    },

    emits: ['changed'],

    computed: {
        pages() {
            return Math.ceil(this.items / this.itemsPerPage);
        },

        prevPages() {
            const total = Math.max(this.current - 1, 0);
            const array = [];

            for (let i = 0; i < Math.min(total, 1); ++i) {
                array.push(1 + i);
            }

            for (let i = Math.max(this.current - 2, 1); i < this.current; ++i) {
                if (array.indexOf(i) === -1) {
                    array.push(i);
                }
            }

            return array;
        },

        nextPages() {
            const total = this.pages - this.current;
            const array = [];

            for (let i = 0; i < Math.min(total, 1); ++i) {
                array.unshift(this.pages - i);
            }

            for (let i = this.current + 2; i > this.current; --i) {
                if (i <= this.pages && array.indexOf(i) === -1) {
                    array.unshift(i);
                }
            }

            return array;
        },
    },
};

</script>
