<script>
import { h } from 'vue';

import { LISTINGS } from '@graphql/listings';

import clone from 'lodash/clone';
import qs from 'qs';

export default {
    name: 'FieldGuide',

    props: {
        fieldGuideId: {
            type: Number,
            required: true,
        },

        categoriesMap: {
            type: Object,
            default: () => {
                return {};
            },
        },

        activityIds: {
            type: Array,
            default: () => {
                return [];
            },
        },

        natureIds: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },

    data() {
        return {
            total: 0,
            page: 1,
            limit: 12,
            activity: [],
            nature: [],
            listings: [],
        };
    },

    apollo: {
        listings: {
            query: LISTINGS,
            manual: true,
            variables() {
                return {
                    fieldGuide: this.fieldGuideId,
                    categories: [...this.activity, ...this.nature],
                };
            },
            result({ data, loading }) {
                this.listings = data.entries;
                this.total = data.total;
            },
            error(error) {
                console.error('We‘ve got an error!', error);
            },
        },
    },

    computed: {
        friendlyCategories() {
            // Populate an array of full category info, based off their IDs
            return [
                ...this.getCategories(this.activity),
                ...this.getCategories(this.nature),
            ];
        },

        paginatedListings() {
            const offset = this.limit * (this.page - 1);
            const listings = clone(this.listings);

            return listings.splice(offset, this.limit);
        },
    },

    created() {
        // Populate the categories from supplied IDs
        this.activity = this.activityIds;
        this.nature = this.natureIds;

        this.fetchListings();
    },

    methods: {
        fetchListings() {
            this.$apollo.queries.listings.refetch();
        },

        onActivityChange(values) {
            this.activity = values.categories;

            this.updateCategories();
        },

        onNatureChange(values) {
            this.nature = values.categories;

            this.updateCategories();
        },

        onMapMove() {

        },

        onClickCategory() {
            // What're we supposed to do here??
        },

        onPageChanged(page) {
            this.page = page;

            document.querySelector('#listings-anchor').scrollIntoView({
                behavior: 'smooth',
            });
        },

        updateCategories() {
            // We must convert IDs to Slugs for the URL
            const activity = this.getCategories(this.activity).map((category) => {
                return category.slug;
            }).join('|');

            const nature = this.getCategories(this.nature).map((category) => {
                return category.slug;
            }).join('|');

            const params = {
                activity,
                nature,
            };

            history.replaceState(null, '', `?${qs.stringify(params, { encode: false })}`);

            this.fetchListings();
        },

        getCategories(items) {
            const categories = [];

            if (items && Array.isArray(items)) {
                items.forEach((id) => {
                    const category = this.categoriesMap[id];

                    if (category) {
                        categories.push(category);
                    }
                });
            }

            return categories;
        },
    },

    render() {
        return h('div', this.$slots.default({
            total: this.total,
            limit: this.limit,
            page: this.page,
            listings: this.listings,
            paginatedListings: this.paginatedListings,
            onActivityChange: this.onActivityChange,
            onNatureChange: this.onNatureChange,
            onMapMove: this.onMapMove,
            onPageChanged: this.onPageChanged,
            friendlyCategories: this.friendlyCategories,
        }));
    },
};

</script>
