<script>
import { h } from 'vue';

export default {
    name: 'EnlargeGallery',

    methods: {
        onClick(index) {
            const $gallery = this.$el.querySelector('.js-spotlight-gallery');

            console.log(index);

            if ($gallery) {
                $gallery.children[index].click();
            }
        },
    },

    render() {
        return h('div', this.$slots.default({
            onClick: this.onClick,
        }));
    },
};

</script>
