import gql from 'graphql-tag';

const LISTINGS = gql`
query(
    $fieldGuide: [QueryArgument],
    $categories: [QueryArgument],
    $limit: Int,
    $offset: Int
) {
    total: entryCount(
        section: "listings",
        fieldGuide: $fieldGuide,
        relatedTo: $categories,
    )

    entries(
        section: "listings",
        fieldGuide: $fieldGuide,
        relatedTo: $categories,
        limit: $limit,
        offset: $offset,
    ) {
        id
        title
        url

        ... on listings_listings_Entry {
            listingDescription
            
            listingTime
            listingTimeMeasurement
            listingDistance
            listingDistanceMeasurement

            listingKmlFile {
                kml: url @kml
            }

            listingAddress {
                lat
                lng
            }

            listingFeaturedImage(limit: 1) {
                url @transform (handle: "large")
            }

            listingActivityCategory(limit: 1) {
                title

                ... on activities_activity_Entry {
                    activitiesIcon {
                        inline
                    }
                }
            }

            listingNatureCategory(limit: 1) {
                title

                ... on natureHeritage_Category {
                    natureIcon {
                        inline
                    }
                }
            }
        }
    }
}
`;

export {
    LISTINGS,
};
