document.addEventListener('DOMContentLoaded', () => {
    // Select all anchor links in the table of contents
    const tocLinks = document.querySelectorAll('#table-of-contents a');

    // Offset for the sticky table of contents (adjust if needed)
    const tocHeight = document.querySelector('#table-of-contents').offsetHeight;

    // Add a click event listener to each link
    tocLinks.forEach((link) => {
        link.addEventListener('click', function(event) {
            event.preventDefault();

            // Get the target section based on the link's href attribute
            const targetId = this.getAttribute('href').substring(1); // Remove the "#" from href
            const targetSection = document.getElementById(targetId);

            if (targetSection) {
                // Calculate the position of the target section and adjust for the sticky TOC height
                const sectionPosition = targetSection.getBoundingClientRect().top + window.pageYOffset - tocHeight - 20; // Adjust with extra 20px padding
                window.scrollTo({
                    top: sectionPosition,
                    behavior: 'smooth', // Smooth scroll effect
                });
            }
        });
    });
});
