<template>
    <VueMultiselect
        ref="dropdown"
        v-model="value"
        v-bind="$attrs"
        :searchable="false"
        :max-height="600"
        label="label"
        track-by="value"
        @select="onSelectChange"
        @remove="onSelectChange"
    >
        <template #selection="{ values }">
            <span v-if="values.length">{{ values.length }} options selected</span>
        </template>

        <template #tag>{{ '' }}</template>

        <template #option="props">
            <div class="flex items-center justify-between" :class="{ 'text-sm': props.option.small }">
                <span class="flex item-center" :class="{ 'pr-4': $attrs.multiple }">
                    <span v-if="props.option.icon" class="icon w-4 h-4 mr-2" style="margin-top: 1px;" v-html="props.option.icon"></span>
                    {{ props.option.label }}
                </span>

                <span v-if="$attrs.multiple" class="icon" :class="[ props.option.small ? 'w-3 h-3' : 'w-4 h-4' ]">
                    <!-- eslint-disable-next-line -->
                    <svg v-if="isSelected(props.option)" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"></path></svg>
                    <!-- eslint-disable-next-line -->
                    <svg v-else aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M400,32H48C21.5,32,0,53.5,0,80v352c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V80C448,53.5,426.5,32,400,32z M400,432H48V80h352V432z" /></svg>
                </span>
            </div>
        </template>
    </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
    name: 'Dropdown',

    components: {
        VueMultiselect,
    },

    props: {
        name: {
            type: String,
            required: true,
            default: '',
        },

        initialValue: {
            type: [Array, String],
            default: '',
        },
    },

    emits: ['change'],

    data() {
        return {
            value: [],
        };
    },

    created() {
        if (Array.isArray(this.initialValue)) {
            if (this.initialValue.length) {
                this.setInitialOption();
            }
        } else if (this.initialValue) {
            this.setInitialOption();
        }
    },

    methods: {
        isSelected(option) {
            const $dropdown = this.$refs.dropdown;

            if ($dropdown) {
                return $dropdown.isSelected(option);
            }

            return false;
        },

        setInitialOption() {
            // vue-multi-select doesn't support setting the value by the string or array of IDs like you
            // normally would for a control. Instead, expecting the full object for the option. That's just annoying.`
            if (Array.isArray(this.initialValue)) {
                this.initialValue.forEach((value) => {
                    const foundOption = this.$attrs.options.find((option) => { return option.value == value; });

                    if (foundOption) {
                        this.value.push(foundOption);
                    }
                });
            } else {
                this.value = this.$attrs.options.find((option) => { return option.value === this.initialValue; });
            }
        },

        onSelectChange(option) {
            // Wait a sec for the value to be set
            setTimeout(() => {
                let parsedValue = this.value.value;

                // Return the entire value of this field, not just the option that was selected
                // vue-multi-select will return the object like `{ label: 'Label', value: 'value' }`
                // but we only want the `value` property, so filter that out. Of course, dealing with `multiple`.
                if (Array.isArray(this.value)) {
                    parsedValue = this.value.map((item) => { return item.value; });
                }

                // Emit the current field value like `{ orderBy: 'postDate' }` or `{ categories: [123, 456] }`
                this.$emit('change', {
                    [this.name]: parsedValue,
                });
            }, 50);
        },
    },
};

</script>
