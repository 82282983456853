const getCsrfToken = async() => {
    const response = await fetch('/actions/users/session-info', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    });

    return response.json();
};

export default getCsrfToken;
