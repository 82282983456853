<script setup>
import { ref } from 'vue';

const isOpen = ref(false);

const setIsOpen = (value) => {
    isOpen.value = value;
};
</script>

<template>
    <slot :is-open="isOpen" :set-is-open="setIsOpen"></slot>
</template>
