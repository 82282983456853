<script>
import {
    h, ref, onMounted, toRaw,
} from 'vue';
import { watchDebounced, useUrlSearchParams } from '@vueuse/core';
import { useAccountStore } from '@stores/account';
import { storeToRefs } from 'pinia';

export default {
    setup(_, { slots }) {
        const container = ref(null);
        const loading = ref(false);

        const accountStore = useAccountStore();
        const { followedElements } = storeToRefs(accountStore);

        onMounted(async() => {
            await accountStore.getFollowedElements();
        });

        const count = ref(0);
        const items = ref([]);
        const limit = ref(12);
        const searchParams = useUrlSearchParams('history');

        const fetchData = async() => {
            // Fetch the HTML and replace via DOM. ugly, but whatevs
            const response = await fetch(`/feed/filtered-articles?limit=${limit.value}`);

            const data = await response.json();

            return data;
        };

        const loadMore = async() => {
            limit.value = limit.value + 12;
            searchParams.page = searchParams.page ? searchParams.page + 1 : 2;

            loading.value = true;

            const data = await fetchData();

            items.value = data.items;

            loading.value = false;
        };

        const unfollowElement = async(id, type) => {
            loading.value = true;

            await accountStore.unfollowElement(id, type);

            loading.value = false;
        };

        watchDebounced(followedElements, async(newValue, oldValue) => {
            loading.value = true;

            limit.value = searchParams.page ? searchParams.page * 12 : 12;

            const data = await fetchData();

            count.value = data.count;
            items.value = data.items;

            loading.value = false;
        }, 400);

        return () => {
            return h('div', { ref: container }, slots.default({
                loading: loading.value,
                followedElements: followedElements.value,
                loadMore,
                unfollowElement,
                totalItems: count.value,
                entries: items.value,
            }));
        };
    },
};
</script>
