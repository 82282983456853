<script setup>
import { computed, onMounted, ref } from 'vue';

import {
    Combobox,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue';

import { useAccountStore } from '@stores/account';
import { storeToRefs } from 'pinia';
import FollowButton from './FollowButton.vue';

const props = defineProps({
    people: {
        type: Array,
        required: true,
    },
    groups: {
        type: Array,
        required: true,
    },
    topics: {
        type: Array,
        required: true,
    },
    regions: {
        type: Array,
        required: true,
    },
});

const accountStore = useAccountStore();

const { followedElementIds } = storeToRefs(accountStore);

onMounted(() => {
    accountStore.getFollowedElements();
});

const query = ref('');

const tabs = ['people', 'groups', 'topics', 'regions'];

const activeTab = ref('people');

const updateTab = (tab) => {
    activeTab.value = tab;
    query.value = '';
};

const items = computed(() => {
    return props[activeTab.value] ?? [];
});

const filteredItems = computed(() => {
    return query.value === ''
        ? items.value.filter((item) => {
            return !item.title.includes('Anon');
        })
        : items.value.filter((item) => {
            return (
                !item.title.includes('Anon') &&
                item.title.toLowerCase().includes(query.value.toLowerCase())
            );
        });
});

</script>

<template>
    <Combobox>
        <div class="px-6 pt-6 mb-3">
            <div class="relative">
                <ComboboxInput
                    class="form-input w-full h-full pl-12 text-grey-500 bg-grey-100 border-grey-100 rounded-lg"
                    placeholder="Type something..."
                    @change="query = $event.target.value"
                />

                <span class="absolute align-y left-4 icon w-4 h-4">
                    <svg
                        aria-hidden="true" focusable="false" data-prefix="far" data-icon="search" class="svg-inline--fa fa-search fa-w-16" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                    >
                        <path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" />
                    </svg>
                </span>
            </div>
        </div>

        <div class="flex flex-row gap-2 px-6 pb-4">
            <button v-for="tab in tabs" :key="tab" type="button" class="px-3 py-1 text-xs border border-grey-100 bg-grey-100 rounded-md" :class="{ 'bg-secondary-500 text-white border-secondary-500': tab === activeTab }" @click.prevent="updateTab(tab)">
                {{ tab.charAt(0).toUpperCase() + tab.slice(1) }}
            </button>
        </div>

        <ComboboxOptions class="pb-4" static>
            <div class="h-[420px] max-h-full overflow-y-auto px-6">
                <div class="grid grid-cols-1 divide-y divide-gray-200">
                    <ComboboxOption
                        v-for="item in filteredItems"
                        :key="item.id"
                        :value="item.id"
                    >
                        <span class="flex items-center justify-between py-2.5">
                            <span class="font-bold">{{ item.title }}</span>

                            <FollowButton
                                v-slot="{ following, loading }"
                                :element-id="item.id"
                                :element-type="item.type"
                                :followed="followedElementIds.includes(item.id)"
                            >
                                <span class="block px-4 py-1.5 text-xs uppercase font-bold rounded-full" :class="[following ? 'bg-secondary-500 text-primary-100' : 'bg-primary-100 text-secondary-500', loading ? 'loading' : '']">
                                    <span v-if="following">Following</span>
                                    <span v-else>Follow</span>
                                </span>
                            </FollowButton>
                        </span>
                    </ComboboxOption>
                </div>
            </div>
        </ComboboxOptions>
    </Combobox>
</template>
