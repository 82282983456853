<template>
    <div class="absolute inset-0 z-10 block">
        <div ref="map" class="relative block w-full h-full z-10"></div>

        <div v-if="loading" class="w-full h-full absolute z-10 bg-white bg-opacity-75 top-0">
            <span class="loading loading-lg absolute top-1/2 left-1/2 align-x-y"></span>
        </div>

        <listing-card v-for="(listing) in listings" :id="'listing-popup-' + listing.id" :key="listing.id" :listing="listing" class="hidden" />
    </div>
</template>

<script>
import L from 'leaflet';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { default as ListingCard } from './ListingCard.vue';

export default {
    name: 'FieldGuideMap',

    components: {
        ListingCard,
    },

    props: {
        listings: {
            type: [Array, null],
            default: null,
        },

        center: {
            type: [Array, null],
            default: null,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        staticMap: {
            type: Boolean,
            default: false,
        },

        showPopup: {
            type: Boolean,
            default: true,
        },

        zoom: {
            type: Number,
            default: 14,
        },
    },

    emits: ['move'],

    data() {
        return {
            map: null,
            mapMarkers: [],
            disableEvents: false,
        };
    },

    watch: {
        listings(newValue) {
            this.$nextTick(() => {
                this.setMapPins();
            });
        },
    },

    mounted() {
        this.map = L.map(this.$refs.map, {
            attributionControl: false,
            zoomControl: false,
            doubleClickZoom: !this.staticMap,
            scrollWheelZoom: !this.staticMap,
            dragging: !this.staticMap,
            sleep: false,
            layers: [
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                }),
            ],
        });

        this.$nextTick(() => {
            this.setMapPins();
        });

        L.control.attribution({
            position: 'bottomright',
        }).addTo(this.map);

        if (this.staticMap) {
            return;
        }

        L.control.zoom({
            position: 'bottomleft',
        }).addTo(this.map);

        this.map.on('moveend', this.onMapMove);
    },

    beforeUnmount() {
        this.map.remove();
    },

    methods: {
        setMapPins() {
            // Reset any existing markers
            for (const marker of this.mapMarkers) {
                this.map.removeLayer(marker);
            }

            const latLngBounds = new L.latLngBounds();

            this.listings.forEach((listing) => {
                const listingAddress = listing.listingAddress || {};
                const markerIcon = this.icon(listing);

                if (listingAddress.lat && listingAddress.lng) {
                    const latLng = L.latLng(listingAddress.lat, listingAddress.lng);

                    // Extend the bounds of each marker to set the zoom/bounds correctly
                    latLngBounds.extend(latLng);

                    const markerHtml = `<div class="relative block w-9 h-auto text-center">
                        <span class="absolute top-0 mt-2 left-1/2 transform -translate-x-1/2 icon w-5 h-5 text-white">${markerIcon}</span>
                        <svg class="block w-9 h-auto text-tertiary-500" aria-hidden="true" focusable="false" data-prefix="fas" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentColor" d="M172.3,501.7C27,291,0,269.4,0,192C0,86,86,0,192,0s192,86,192,192c0,77.4-27,99-172.3,309.7C202.2,515.4,181.8,515.4,172.3,501.7L172.3,501.7z" stroke="#fff" stroke-miterlimit="10" stroke-width="20"/>
                        </svg>
                    </div>`;

                    // Create a custom icon for the marker
                    const icon = L.divIcon({
                        id: listing.id,
                        html: markerHtml,
                        iconSize: [36, 48],
                    });

                    // Create a marker
                    const mapMarker = L.marker(latLng, { icon });
                    const card = this.$el.querySelector(`#listing-popup-${listing.id}`);
                    const cardHtml = card ? card.innerHTML : '';

                    // Create the popup HTML using the rendered (hidden) card HTML
                    if (!this.staticMap && this.showPopup) {
                        mapMarker.bindPopup(cardHtml, {
                            maxWidth: 200,
                            minWidth: 200,
                            closeButton: false,
                        }).openPopup();

                        // Fix for desktop Safari not working when clicking on a marker.
                        // Fix should be applicable in Leaflet 1.7.2+.
                        if (/Safari/i.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor) && !/Mobi|Android/i.test(navigator.userAgent)) {
                            mapMarker.on('click', function(e) {
                                this.openPopup();
                            });
                        }
                    }

                    // Add the marker to the map
                    this.mapMarkers.push(mapMarker);
                    this.map.addLayer(mapMarker);

                    // When hovering over a marker, toggle the show/hide of the path polyline
                    if (!this.staticMap) {
                        mapMarker.on('mouseover', (e) => {
                            const id = get(e.target, 'options.icon.options.id', null);

                            if (id) {
                                const polyline = this.$el.querySelector(`.listing-polyline-${id}`);

                                if (polyline) {
                                    polyline.classList.remove('hidden');
                                }
                            }
                        });

                        mapMarker.on('mouseout', (e) => {
                            const id = get(e.target, 'options.icon.options.id', null);

                            if (id) {
                                const polyline = this.$el.querySelector(`.listing-polyline-${id}`);

                                if (polyline) {
                                    polyline.classList.add('hidden');
                                }
                            }
                        });
                    }
                }

                if (this.staticMap) {
                    return;
                }

                // Check if there's a KML file to plot
                if (listing.listingKmlFile.length && listing.listingKmlFile[0].kml) {
                    const kmlPath = JSON.parse(listing.listingKmlFile[0].kml);
                    const className = (this.staticMap) ? '' : 'hidden';

                    const polyline = L.polyline(kmlPath, {
                        stroke: true,
                        weight: 3,
                        color: 'red',
                        dashArray: [5, 10],
                        className: `${className} listing-polyline-${listing.id}`,
                    });

                    polyline.addTo(this.map);

                    // Update the map boundaries to factor in the polylines
                    latLngBounds.extend(polyline.getBounds());
                }
            });

            if (!isEmpty(latLngBounds)) {
                this.map.fitBounds(latLngBounds, {
                    maxZoom: this.zoom,
                    padding: [100, 100],
                });
            }
        },

        onMapMove: debounce(function(event) {
            if (!this.disableEvents && this.map.getZoom() >= 7) {
                const bounds = this.map.getBounds();

                this.$emit('move', bounds);
            }

            this.disableEvents = false;
        }, 1000),

        category(listing) {
            const activity = get(listing, 'listingActivityCategory.0', {});
            const nature = get(listing, 'listingNatureCategory.0', {});

            if (!isEmpty(activity)) {
                return activity;
            }

            return nature;
        },

        icon(listing) {
            const activitiesIcon = get(this.category(listing), 'activitiesIcon.inline', '');
            const natureIcon = get(this.category(listing), 'natureIcon.inline', '');

            if (activitiesIcon) {
                return activitiesIcon;
            }

            return natureIcon;
        },
    },
};

</script>

<style>

.leaflet-container {
    font: inherit !important;
}

.leaflet-container a {
    color: inherit;
}

.leaflet-popup-content p {
    margin: 0;
}

.leaflet-popup-content-wrapper {
    background: transparent;
    color: inherit;
    box-shadow: none;
    padding: 0;
    text-align: left;
    border-radius: 0;
}

.leaflet-popup-content {
    margin: 0;
    line-height: inherit;
}

</style>
