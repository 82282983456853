import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import getCsrfToken from '@utils/csrftoken';

export const useAccountStore = defineStore('account', () => {
    const followedElements = useLocalStorage('kuno/account', []);

    const followedElementIds = computed(() => {
        const ids = [];

        followedElements.value.forEach((item) => {
            ids.push(item.id);
        });

        return ids;
    });

    const getFollowedElements = async() => {
        const response = await fetch('/account/followed-elements');
        const data = await response.json();

        followedElements.value = data;
    };

    const unfollowElement = async(id, type) => {
        const csrfToken = await getCsrfToken();

        const response = await fetch('/account/unfollow-element', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                CRAFT_CSRF_TOKEN: csrfToken.csrfTokenValue,
                elementId: id,
                elementType: type,
            }),
        });

        const data = await response.json();

        followedElements.value = data;
    };

    const followElement = async(id, type) => {
        const csrfToken = await getCsrfToken();

        const response = await fetch('/account/follow-element', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                CRAFT_CSRF_TOKEN: csrfToken.csrfTokenValue,
                elementId: id,
                elementType: type,
            }),
        });

        const data = await response.json();

        followedElements.value = data;
    };

    return {
        followedElements,
        followedElementIds,
        getFollowedElements,
        followElement,
        unfollowElement,
    };
});
