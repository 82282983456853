<template>
    <div :class="mapCard ? 'w-50' : 'px-2 w-full sm:w-1/2 md:w-1/3 mb-4'">
        <div class="hover:shadow-card-hover h-full" :class="{ 'card': mapCard }">
            <a class="block" :href="listing.url" :target="mapCard ? '_blank' : ''">
                <div class="img-cover w-full aspect aspect-3x2 lazyload" :data-bg="image"></div>
            </a>

            <div class="p-4 bg-white">
                <h6 class="flex items-start font-heading text-xs font-bold tracking-wide text-tertiary-500 uppercase mb-2">
                    <span class="icon w-4 h-4 mr-1" v-html="icon"></span>
                    <div class="">{{ category.title }}</div>
                </h6>

                <h3 class="h3 mb-2 text-lg font-bold text-secondary-500">
                    <a :href="listing.url" :target="mapCard ? '_blank' : ''">{{ listing.title }}</a>
                </h3>

                <div v-if="(listing.listingTime && listing.listingTimeMeasurement) || (listing.listingDistance && listing.listingDistanceMeasurement)">
                    <div v-if="listing.listingTime && listing.listingTimeMeasurement" class="flex items-start text-gray-700 text-sm mb-1">
                        <div class="relative icon mr-2 mt-1 w-3 h-3 flex-none">
                            <!-- eslint-disable-next-line -->
                        <svg aria-hidden="true" data-prefix="far" data-icon="clock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-clock fa-w-16"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path></svg>
                        </div>

                        <p>{{ listing.listingTime }} {{ listing.listingTimeMeasurement }}</p>
                    </div>

                    <div v-if="listing.listingDistance && listing.listingDistanceMeasurement" class="flex items-start text-gray-700 text-sm">
                        <div class="relative icon mr-2 mt-1 w-3 h-3 flex-none">
                            <!-- eslint-disable-next-line -->
                        <svg aria-hidden="true" data-prefix="far" data-icon="location-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-location-arrow fa-w-16"><path fill="currentColor" d="M461.9 0c-5.73 0-11.59 1.1-17.39 3.52L28.74 195.41c-47.97 22.39-31.98 92.75 19.19 92.75h175.91v175.91c0 30.01 24.21 47.93 48.74 47.93 17.3 0 34.75-8.9 44.01-28.74l191.9-415.78C522.06 34.89 494.14 0 461.9 0zM271.81 464.07V240.19h-47.97l-175.48.71c-.27-.37-.47-1.35.48-1.93L462.05 48.26c.61.41 1.28 1.07 1.69 1.68L271.81 464.07z"></path></svg>
                        </div>

                        <p>{{ listing.listingDistance }} {{ listing.listingDistanceMeasurement }}</p>
                    </div>
                </div>

                <div v-else>
                    <div class="mb-3 text-sm leading-5" v-html="listing.listingDescription"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'ListingCard',

    props: {
        listing: {
            type: Object,
            default: () => {},
        },

        mapCard: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        image() {
            return get(this.listing, 'listingFeaturedImage.0.url', '');
        },

        category() {
            const activity = get(this.listing, 'listingActivityCategory.0', {});
            const nature = get(this.listing, 'listingNatureCategory.0', {});

            if (!isEmpty(activity)) {
                return activity;
            }

            return nature;
        },

        icon() {
            const activitiesIcon = get(this.category, 'activitiesIcon.inline', '');
            const natureIcon = get(this.category, 'natureIcon.inline', '');

            if (activitiesIcon) {
                return activitiesIcon;
            }

            return natureIcon;
        },
    },
};

</script>
