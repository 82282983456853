<script setup>
import { useStorage } from '@vueuse/core';
import { onMounted, ref } from 'vue';

const props = defineProps({
    open: {
        type: Boolean,
        default: false,
    },
});

const isOpen = ref(props.open);
const dismissed = useStorage('membership-popup-dismissed', false);
const clickCount = useStorage('membership-popup-click-count', 0);

const setIsOpen = (value) => {
    isOpen.value = value;

    if (!value) {
        dismissed.value = true;
    }
};

onMounted(() => {
    if (dismissed.value) {
        return;
    }

    if (clickCount.value >= 10) {
        setIsOpen(true);
        return;
    }

    const links = document.querySelectorAll('a');

    for (const link of links) {
        link.addEventListener('click', (event) => {
            clickCount.value++;
        });
    }
});

const selectedPlan = ref('Free');

const setSelectedPlan = (value) => {
    selectedPlan.value = value;
};
</script>

<template>
    <slot :is-open="isOpen" :set-is-open="setIsOpen" :selected-plan="selectedPlan" :set-selected-plan="setSelectedPlan"></slot>
</template>
