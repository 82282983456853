<template>
    <div class="rounded-full overflow-hidden will-change-transform">
        <img v-if="photo" class="block w-10 h-10" :class="{ 'w-7 h-7': small }" :src="photo.url" :alt="alt || profile.fullName">

        <div v-else class="flex w-10 h-10 bg-gray-100 items-center justify-center" :class="{ 'w-7 h-7': small }">
            <slot name="empty">
                <span v-if="initials">{{ initials }}</span>
                <span v-else class="loading"></span>
            </slot>
        </div>
    </div>
</template>

<script>
import { isArray, isPlainObject } from 'lodash';

export default {
    name: 'LittlePic',

    props: {
        profile: {
            validator: (value) => { return typeof value === 'object' || value === null; },
            default: null,
        },

        small: {
            type: Boolean,
            default: false,
        },

        url: {
            type: String,
            default: '',
        },

        alt: {
            type: String,
            default: '',
        },
    },

    computed: {
        photo() {
            if (this.url) {
                return { url: this.url };
            }

            if (!this.profile) {
                return null;
            }

            if (isPlainObject(this.profile.photo)) {
                return this.profile.photo;
            }

            if (isArray(this.profile.photo)) {
                const [photo] = this.profile.photo;
                return photo;
            }

            if (isArray(this.profile.image)) {
                const [image] = this.profile.image;
                return image;
            }

            return null;
        },

        initials() {
            let initials = '';

            if (this.alt) {
                initials = this.alt.split(' ').map((comp) => { return comp.toLocaleUpperCase().trim()[0]; }).join('');
                initials.substr(0, 2);
            } else if (this.profile) {
                if (this.profile.firstName) {
                    initials += this.profile.firstName[0];
                }

                if (this.profile.lastName) {
                    initials += this.profile.lastName[0];
                }
            }

            return initials.toLocaleUpperCase();
        },
    },
};

</script>
