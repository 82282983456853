<template>
    <div class="js-pane h-full" @click="onClickPane" @mousedown="onClickPane" @dragstart="onClickPane">
        <div class="absolute inset-0 top-1/2 transform -translate-y-1/2 mt-16 flex justify-center px-4 z-1 transition-opacity duration-300 ease-in-out pointer-events-none" :class="{ 'opacity-0': !showHeading }">
            <div class="w-full max-w-6xl">
                <h1 class="h1 md:text-7xl tracking-tight font-bold text-white text-center mb-4">{{ heading }}</h1>
            </div>
        </div>

        <div id="cesiumContainer" class="w-full h-full"></div>

        <div v-for="(listing, index) in listings" :id="'listing-popup-' + listing.id" :key="index" class="hidden">
            <div class="cesium-infoBox-content-img" :style="`background-image: url('${listing.featuredImage}');`"></div>

            <div class="cesium-infoBox-content-wrap">
                <h3>{{ listing.title }}</h3>

                <div class="" v-html="listing.shortDescription"></div>

                <a style="margin-bottom: 1rem; display: inline-block;" :href="listing.url" target="_blank">Read more</a>
            </div>
        </div>
    </div>
</template>

<script>
window.CESIUM_BASE_URL = '/cesium';

import * as Cesium from 'cesium';

export default {
    name: 'GlobeMap',

    props: {
        listings: {
            type: Array,
            default: () => { return []; },
        },

        heading: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            showHeading: true,
        };
    },

    mounted() {
        Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlYWNlZmE5Mi0wZGI4LTQwNzAtYjM3Zi1iZTkzMTQwZGE3MjEiLCJpZCI6ODc0MiwiaWF0IjoxNjI3NjQwNTE5fQ.fdQ02HNQ3XHmngREtg7U_LVo4PVtOw7ZNTmCJY74RvY';

        const viewer = new Cesium.Viewer('cesiumContainer', {
            animation: false,
            fullscreenButton: false,
            geocoder: false,
            homeButton: false,
            infoBox: true,
            sceneModePicker: false,
            selectionIndicator: false,
            timeline: false,
            navigationHelpButton: false,
            navigationInstructionsInitiallyVisible: false,
            scene3DOnly: true,
            shouldAnimate: true,
            baseLayerPicker: false,
            creditDisplay: false,
            skyAtmosphere: false,
            enableLighting: false,
        });

        // Enabling Blue Marble
        const layers = viewer.scene.imageryLayers;

        const blueMarble = Cesium.ImageryLayer.fromProviderAsync(
            Cesium.IonImageryProvider.fromAssetId(3845),
        );
        blueMarble.alpha = 0.8;
        blueMarble.brightness = 2.0;
        layers.add(blueMarble);

        // Setting default view to Australia
        const defaultView = {
            destination: Cesium.Cartesian3.fromDegrees(135, -45, 6500000),
            orientation: {
                heading: Cesium.Math.toRadians(0.0),
                pitch: Cesium.Math.toRadians(-75),
                roll: 0.0,
            },
        };

        // Zoom in to Australia dn set pitch to be angled
        viewer.camera.setView(defaultView);

        // Remove the sun from bg
        viewer.scene.sun = undefined;

        // Disable for better UX
        viewer.scene.screenSpaceCameraController.enableTranslate = false;
        viewer.scene.screenSpaceCameraController.enableTilt = false;
        viewer.scene.screenSpaceCameraController.enableLook = false;
        viewer.scene.screenSpaceCameraController.maximumZoomDistance = 6500000;

        // Disable zooming in when double-clicking a pin
        viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        // Disable the mouse wheel for zooming.
        viewer.scene.screenSpaceCameraController.zoomEventTypes = [
            Cesium.CameraEventType.RIGHT_DRAG,
            Cesium.CameraEventType.PINCH,
        ];

        viewer.infoBox.frame.removeAttribute('sandbox');
        viewer.infoBox.frame.src = 'about:blank';

        // Cesium will hijack the scroll when over the container. Add it back
        document.getElementById('cesiumContainer').addEventListener('wheel', (event) => {
            window.scrollBy({ top: event.deltaY, behavior: 'smooth' });
        });

        // Custom CSS for infobox
        const { frame } = viewer.infoBox;

        frame.addEventListener('load', () => {
            const fontLink = frame.contentDocument.createElement('link');
            fontLink.href = 'https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&family=Nunito+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap';
            fontLink.rel = 'stylesheet';
            frame.contentDocument.head.appendChild(fontLink);

            // Be sure to cache-bust the CSS when you make changes
            const cssLink = frame.contentDocument.createElement('link');
            cssLink.href = Cesium.buildModuleUrl('cesium-infobox.css');
            cssLink.rel = 'stylesheet';
            cssLink.type = 'text/css';
            frame.contentDocument.head.appendChild(cssLink);
        }, false);


        // Find Cartesian3 on-click to easily get position
        // var handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
        // handler.setInputAction((event) => {
        //     console.log(viewer.scene.pickPosition(event.position));
        // }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

        // Auto-rotate
        // viewer.camera.flyHome(0);
        // viewer.clock.multiplier = 1500;
        // viewer.scene.postUpdate.addEventListener((scene, time) => {
        //     var icrfToFixed = Cesium.Transforms.computeIcrfToFixedMatrix(time);

        //     if (Cesium.defined(icrfToFixed)) {
        //         var offset = Cesium.Cartesian3.clone(viewer.camera.position);
        //         var transform = Cesium.Matrix4.fromRotationTranslation(icrfToFixed);
        //         viewer.camera.lookAtTransform(transform, offset);
        //     }
        // });

        // Add pins to the map
        this.listings.forEach((listing, index) => {
            // Fetch the HTML for the info box
            const card = this.$el.querySelector(`#listing-popup-${listing.id}`);
            const cardHtml = card ? card.innerHTML : '';

            let icon = '/dist/img/icon-map-marker-red.svg';

            if (listing.type === 'fieldGuide') {
                icon = '/dist/img/icon-map-marker-purple.svg';
            }

            if (listing.type === 'article') {
                icon = '/dist/img/icon-map-marker-green.svg';
            }

            const marker = viewer.entities.add({
                name: listing.title,
                position: Cesium.Cartesian3.fromDegrees(listing.lng, listing.lat),
                billboard: {
                    image: icon,
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                },
                description: cardHtml,
            });
        });

        // Add zoom controls
        const $zoomWrapper = document.createElement('div');
        $zoomWrapper.setAttribute('class', 'cesium-zoom-controls');

        const $zoomIn = document.createElement('button');
        $zoomIn.setAttribute('type', 'button');
        $zoomIn.setAttribute('title', 'Zoom in');
        $zoomIn.setAttribute('class', 'cesium-zoom-in');

        const $zoomInIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        $zoomInIcon.setAttribute('viewBox', '0 0 50 50');

        const $zoomInIconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        $zoomInIconPath.setAttribute('d', 'M0,25 C0,25.3514939 0.131810207,25.659051 0.373462207,25.900703 C0.615114207,26.142355 0.922671379,26.2741652 1.27416517,26.2741652 L23.7258348,26.2741652 L23.7258348,48.7258348 C23.7258348,49.0773286 23.857645,49.3848858 24.099297,49.6265378 C24.3189807,49.8462214 24.6485061,50 25,50 C25.7029877,50 26.2741652,49.4288225 26.2741652,48.7258348 L26.2741652,26.2741652 L48.7258348,26.2741652 C49.4288225,26.2741652 50,25.7029877 50,25 C50,24.2970123 49.4288225,23.7258348 48.7258348,23.7258348 L26.2741652,23.7258348 L26.2741652,1.27416517 C26.2741652,0.571177517 25.7029877,0 25,0 C24.2970123,0 23.7258348,0.571177517 23.7258348,1.27416517 L23.7258348,23.7258348 L1.27416517,23.7258348 C0.571177517,23.7258348 0,24.2970123 0,25 L0,25 L0,25 L0,25 Z');

        $zoomInIcon.appendChild($zoomInIconPath);
        $zoomIn.appendChild($zoomInIcon);

        const $zoomReset = document.createElement('button');
        $zoomReset.setAttribute('type', 'button');
        $zoomReset.setAttribute('title', 'Reset zoom');
        $zoomReset.setAttribute('class', 'cesium-zoom-reset');

        const $zoomResetIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        $zoomResetIcon.setAttribute('viewBox', '0 0 50 50');

        const $zoomResetIconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        $zoomResetIconPath.setAttribute('d', 'M48.2758621,0 C47.2844828,0 46.5086207,0.775193846 46.5086207,1.76571923 L46.5086207,12.2308355 C42.0689655,4.78036173 34.0086207,0 25,0 C11.2068965,0 0,11.1972438 0,25.0215332 C0,38.8458226 11.2068965,50 25,50 C38.7931035,50 50,38.8027562 50,25.0215332 C50,24.0310078 49.2241379,23.2558139 48.2327587,23.2558139 C47.2413793,23.2558139 46.4655172,24.0310078 46.4655172,25.0215332 C46.4655172,36.8647717 36.8103448,46.5116279 24.9568965,46.5116279 C13.1034483,46.5116279 3.49137933,36.8217054 3.49137933,24.9784668 C3.49137933,13.1352283 13.1465517,3.48837212 25,3.48837212 C33.4913793,3.48837212 41.0775862,8.44099913 44.5258621,16.0206718 L32.1551724,16.0206718 C31.1637931,16.0206718 30.3879311,16.7958657 30.3879311,17.7863911 C30.3879311,18.7769164 31.1637931,19.5521103 32.1551724,19.5521103 L48.2327587,19.5521103 C49.2241379,19.5521103 50,18.7769164 50,17.7863911 L50,1.72265288 C50,0.775193846 49.2241379,0 48.2758621,0 L48.2758621,0 L48.2758621,0 Z');

        $zoomResetIcon.appendChild($zoomResetIconPath);
        $zoomReset.appendChild($zoomResetIcon);

        const $zoomOut = document.createElement('button');
        $zoomOut.setAttribute('type', 'button');
        $zoomOut.setAttribute('title', 'Zoom out');
        $zoomOut.setAttribute('class', 'cesium-zoom-out');

        const $zoomOutIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        $zoomOutIcon.setAttribute('viewBox', '0 0 50 6');

        const $zoomOutIconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        $zoomOutIconPath.setAttribute('d', 'M46.6183575,0.657894737 L3.30112724,0.657894737 C1.44927539,0.657894737 0,1.66880618 0,2.96052632 C0,4.25224645 1.44927539,5.26315789 3.30112724,5.26315789 L46.6988728,5.26315789 C48.5507246,5.26315789 50,4.25224645 50,2.96052632 C49.9194847,1.66880618 48.4702093,0.657894737 46.6183575,0.657894737 L46.6183575,0.657894737 L46.6183575,0.657894737 Z');

        $zoomOutIcon.appendChild($zoomOutIconPath);
        $zoomOut.appendChild($zoomOutIcon);

        $zoomWrapper.appendChild($zoomIn);
        $zoomWrapper.appendChild($zoomReset);
        $zoomWrapper.appendChild($zoomOut);

        viewer.container.appendChild($zoomWrapper);

        const zoomFactor = 0.2;

        $zoomIn.addEventListener('click', (e) => {
            e.preventDefault();

            viewer.camera.zoomIn(viewer.scene.camera.positionCartographic.height * zoomFactor);
        });

        $zoomOut.addEventListener('click', (e) => {
            e.preventDefault();

            const zoomAmount = viewer.scene.camera.positionCartographic.height * zoomFactor;

            if ((zoomAmount / zoomFactor) > viewer.scene.screenSpaceCameraController.maximumZoomDistance) {
                return;
            }

            viewer.camera.zoomOut(zoomAmount);
        });

        $zoomReset.addEventListener('click', (e) => {
            e.preventDefault();

            viewer.camera.flyTo({
                duration: 1,
                ...defaultView,
            });
        });
    },

    methods: {
        onClickPane() {
            this.showHeading = false;
        },
    },
};

</script>

<style>

.cesium-viewer-bottom {
    display: none;
}

button.cesium-infoBox-camera {
    display: none;
}

.cesium-infoBox {
    background: #fff;
    color: #444;
    border: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);

    z-index: 1;
    top: 80px;
    width: 90%;
}

@media (min-width: 992px) {
    .cesium-infoBox {
        top: 100px;
        width: 40%;
    }
}

.cesium-infoBox-title {
    display: none;
    height: 20px;
    padding: 5px 30px 5px 25px;
    background: #fff;
    border-top-left-radius: 8px;
}

button.cesium-infoBox-close {
    height: 36px;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    border-radius: 0;
    font-weight: 400;
    font-size: 28px;
    padding: 10px;
    line-height: 0.5;
    margin: 0;
    color: #fff;
    text-shadow: 0 0 6px rgba(0 0 0, 70%);
}

button.cesium-infoBox-close:hover {
    background: transparent;
    color: #fff;
}

</style>
